.header {
    position: relative;
    z-index: 10;
    width: 100%;
    box-shadow: 0px 7px 22px 0px rgba(38, 67, 84, 0.1);
    background-color: white;
    padding: 0;
    display: flex;
    margin: 0 auto;
    padding: 0 13px 0 14px;
}

@media (max-width: 834px) {
    .header {
        padding: 42px 10px 0;
        background-color: transparent;
        box-shadow: none;
    }
}
